import React, { useState, useEffect } from 'react';
import './slider.css';
import cbdImage from '../assets/cbd2.jpg'; // Import the image
import marketImage from '../assets/market2.webp'; // Import the image
import dataImage  from '../assets/data.webp'; // Import the image
import analysisImage from '../assets/market4.jpg';
import { Link } from 'react-router-dom';

const slides = [
    {
        image: cbdImage,
        text: {
            heading: 'Transform Your Business with Cutting-Edge Technology',
            description: 'Discover the innovation and technology NEXT delivers to transform what\'s next into what\'s now.'
        }
    },
    {
        image: marketImage,
        text: {
            heading: 'Transform Your Business with Cutting-Edge Technology',
            description: 'Discover the innovation and technology NEXT delivers to transform what\'s next into what\'s now.'
        }
    },
    {
        image: dataImage,
        text: {
            heading: 'Transform Your Business with Cutting-Edge Technology',
            description: 'Discover the innovation and technology NEXT delivers to transform what\'s next into what\'s now.'
        }
    },
    // {
    //     image: analysisImage, // Replace with the actual path to the new image
    //     text: {
    //         heading: 'Transform Your Business with Cutting-Edge Technology',
    //         description: 'Discover the innovation and technology NEXT delivers to transform what\'s next into what\'s now.'
    //     }
    // }
];

// const Slider = () => {
//     const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
//
//     useEffect(() => {
//         const interval = setInterval(() => {
//             setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
//         }, 8000);
//         return () => clearInterval(interval);
//     }, []);
//
//     return (
//         <div className="slider">
//             {slides.map((slide, index) => (
//                 <div
//                     key={index}
//                     className={`slide ${index === currentSlideIndex ? 'active' : ''}`}
//                 >
//                     <img
//                         src={slide.image}
//                         alt={`Slide ${index}`}
//                         className="slide-image"
//                     />
//                     <div className="slider-text">
//                         <h1>{slide.text.heading}</h1>
//                         <p>{slide.text.description}</p>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };
//
// export default Slider;

const Slider = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 8000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slider">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`slide ${index === currentSlideIndex ? 'active' : ''}`}
                >
                    <img
                        src={slide.image}
                        alt={`Slide ${index}`}
                        className="slide-image"
                    />
                    <div className="slider-text">
                        <h1>{slide.text.heading}</h1>
                        <p>{slide.text.description}</p>
                        {/* <a href="/contact" className="request-demo-btn">Request Demo</a>  */}
                        <Link  to="/contact" className="request-demo-btn">Request Demo</Link> {/* CTA button */}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Slider;