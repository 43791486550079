import React from 'react';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import '../styles/service.css';

function SES1() {
    return (
        <div className="service-page">
            <Navigation />
            <div className="service-content">
                <div className="service-description">
                    <h1>Boost ROI with AI-Enhanced WhatsApp Integration</h1>
                    <p>Automate personalized marketing, enhance customer relations, and streamline operations on the world's most popular messaging platform with AI.</p>
                    <div className="get-info">
                        <h2>Get More Information</h2>
                        <form>
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="service-images">
                    {/* Add your collage images here */}
                    <img src="/media/image1.jpg" alt="Image 1" />
                    <img src="/media/image2.jpg" alt="Image 2" />
                    <img src="/media/image3.jpg" alt="Image 3" />
                    <img src="/media/image4.jpg" alt="Image 4" />
                    <img src="/media/image5.jpg" alt="Image 5" />
                    <img src="/media/image6.jpg" alt="Image 6" />
                </div>
            </div>
            <div className="service-facts">
                <div className="fact">
                    <h3>3X<span className="medium"> convertion vs email</span></h3>
                    <p><span className="small">WhatsApp</span> outperforms email in terms of conversions by <span className="small">3x</span></p>
                </div>
                <div className="fact">
                    <h3>2 Billion<span className="medium"> Monthly active users</span></h3>
                    <p>Reach <span className="small">2B</span> people on the world's most used chat app.</p>
                </div>
                <div className="fact">
                    <h3>75%<span className="medium"> Average open rate</span></h3>
                    <p>On average, WhatsApps achieve a <span className="small">75%</span> open rate.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SES1;
