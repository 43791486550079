import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-slogan">
                <h2>Lead Your Business Forward</h2>
            </div>
            <div className="footer-content">
                <div className="services left">
                    <h3>Software Engineering & Development</h3>
                    <ul>
                        <li>WhatsApp AI Integration</li>
                        <li>Custom API Development</li>
                        <li>Chatbot Solutions</li>
                        <li>Business Process Automation</li>
                    </ul>
                </div>
                <div className="services right">
                    <h3>Marketing and Media</h3>
                    <ul>
                        <li>AI-Driven Analytics and Reporting</li>
                        <li>Media Marketing</li>
                        <li>Social Media Management</li>
                        <li>Strategic Advertising</li>
                    </ul>
                </div>
                {/* <div className="company">
                    <h3>Company</h3>
                    <ul>
                        <li>About Us</li>
                        <li>Case Studies</li>
                        <li>Our Partners</li>
                        <li>Contact Us</li>
                    </ul>
                </div> */}
            </div>
            <div className='copy-right'>
                <p className="copyright">Copyright 2024 NEXT. All Rights Reserved.</p>
            </div>
            <div className="footer-logo">
                <Link to="/" onClick={() => {
                    // window.location.reload();
                    // window.scrollTo(0, 0);
                }}>
                    <img src="/next.png" alt="Footer Logo"/>
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
