import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homepage';
import SES1 from './pages/ses1';
import SES2 from './pages/ses2';
import SES3 from './pages/ses3';
import SES4 from './pages/ses4';
import Contact from './pages/contact';
// import MMS1 from './pages/MMS1';
// import MMS2 from './pages/MMS2';
// import MMS3 from './pages/MMS3';
// import MMS4 from './pages/MMS4';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                 <Route path="/ses1" element={<SES1 />} />
                 <Route path="/ses2" element={<SES2 />} />
                 <Route path="/ses3" element={<SES3 />} />
                 <Route path="/ses4" element={<SES4 />} />
                {/* <Route path="/mms1" element={<MMS1 />} /> */}
                {/* <Route path="/mms2" element={<MMS2 />} /> */}
                {/* <Route path="/mms3" element={<MMS3 />} /> */}
                {/* <Route path="/mms4" element={<MMS4 />} /> */}
                <Route path='/contact' element={<Contact/>}/>
            </Routes>
        </Router>
    );
}

export default App;
