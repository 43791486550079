import React from 'react';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import '../styles/service.css';

function SES2() {
    return (
        <div className="service-page">
            <div className="service-content">
                <div className="service-description">
                    <h1>Maximize Efficiency with Custom API Integration</h1>
                    <p>Maximize the potential of your business applications with tailored API solutions, ensuring seamless integration and improved efficiency.</p>
                    <div className="get-info">
                        <h2>Get More Information</h2>
                        <form>
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="service-images">
                    {/* Add your collage images here */}
                    <img src="/media/api1.jpg" alt="Image 1" />
                    <img src="/media/api2.jpg" alt="Image 2" />
                    <img src="/media/api3.jpg" alt="Image 3" />
                    <img src="/media/api4.jpg" alt="Image 4" />
                    <img src="/media/api5.jpg" alt="Image 5" />
                    <img src="/media/api6.jpg" alt="Image 6" />
                </div>
            </div>
            <div className="service-facts">
                <div className="fact">
                    <h3>Streamlined Operations<span className="medium"> with System Integration</span></h3>
                    <p>Connect disparate systems for more <span className="small">efficient operations.</span></p>
                </div>
                <div className="fact">
                    <h3>Third-Party Services<span className="medium"> Integration</span></h3>
                    <p>Integrate with popular <span className="small">third-party services</span> to expand offerings.</p>
                </div>
                <div className="fact">
                    <h3>Scalable & Secure<span className="medium"> Solutions</span></h3>
                    <p>APIs designed to be <span className="small">scalable and secure</span> for your business needs.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SES2;
