import React from 'react';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import '../styles/service.css';

function SES3() {
    return (
        <div className="service-page">
            <div className="service-content">
                <div className="service-description">
                    <h1>Revolutionize Customer Interaction with Advanced Chatbot Solutions</h1>
                    <p>Leverage cutting-edge chatbot technology to provide 24/7 customer support, boost operational efficiency, and drive sales through personalized interactions.</p>
                    <div className="get-info">
                        <h2>Get More Information</h2>
                        <form>
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="service-images">
                    {/* Add your collage images here */}
                    <img src="/media/image1.jpg" alt="Image 1" />
                    <img src="/media/image2.jpg" alt="Image 2" />
                    <img src="/media/image3.jpg" alt="Image 3" />
                    <img src="/media/image4.jpg" alt="Image 4" />
                    <img src="/media/image5.jpg" alt="Image 5" />
                    <img src="/media/image6.jpg" alt="Image 6" />
                </div>
            </div>
            <div className="service-facts">
                <div className="fact">
                    <h3>Instant Responses<span className="medium"> to Queries</span></h3>
                    <p><span className="small">Chatbots provide</span> instant responses, handling multiple interactions simultaneously.</p>
                </div>
                <div className="fact">
                    <h3>Personalized<span className="medium"> Sales Engagement</span></h3>
                    <p>Drive sales through <span className="small">personalized recommendations and proactive engagement.</span></p>
                </div>
                <div className="fact">
                    <h3>Automated<span className="medium"> Business Operations</span></h3>
                    <p>Improve productivity and accuracy by <span className="small">automating routine tasks.</span></p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SES3;
