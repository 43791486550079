import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import '../styles/service.css';
import '../styles/ses4.css'; // Special CSS for SES4

function SES4() {
    return (
        <div className="ses4-page">
            <div className="ses4-content">
                <div className="ses4-description">
                    <h1>Transform Your Operations with Specialized Business Process Automation</h1>
                    <p>Streamline your financial and procurement processes with tailored automation solutions that enhance efficiency, accuracy, and compliance.</p>
                    <div className="ses4-get-info">
                        <h2>Get More Information</h2>
                        <form>
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="ses4-images">
                    {/* images here */}
                    <img src="/media/image1.jpg" alt="Image 1" />
                    <img src="/media/image2.jpg" alt="Image 2" />
                    <img src="/media/image3.jpg" alt="Image 3" />
                    <img src="/media/image4.jpg" alt="Image 4" />
                    <img src="/media/image5.jpg" alt="Image 5" />
                    <img src="/media/image6.jpg" alt="Image 6" />
                </div>
            </div>
            <div className="ses4-facts">
                <div className="ses4-fact">
                    <h3>50%<span className="ses4-medium"> Time Saved</span></h3>
                    <p>Automate repetitive tasks and focus on strategic growth.</p>
                </div>
                <div className="ses4-fact">
                    <h3>99%<span className="ses4-medium"> Accuracy</span></h3>
                    <p>Ensure compliance and reduce manual errors.</p>
                </div>
                <div className="ses4-fact">
                    <h3>24/7<span className="ses4-medium"> Operations</span></h3>
                    <p>Run your business round the clock with automated processes.</p>
                </div>
            </div>
            <div className="ses4-details">
                <h2>Business Process Automation Solutions</h2>
                <p>Our specialized BPA solutions are designed to optimize your financial and procurement operations, providing you with the tools to:</p>
                <ul>
                    <li><strong>Automate Accounting:</strong> From transaction recording to invoice management, automate your accounting processes for greater accuracy and efficiency.</li>
                    <li><strong>Optimize Procurement:</strong> Streamline vendor management, purchase orders, and inventory control with intelligent procurement automation.</li>
                    <li><strong>Ensure Compliance:</strong> Maintain adherence to regulations with automated audit trails and compliance-ready reports.</li>
                </ul>
                <p>Experience the benefits of enhanced efficiency, reduced errors, and improved compliance with our tailored BPA solutions. Empower your team to focus on what matters most—strategic growth and innovation.</p>
                <Link to="/contact" className="ses4-request-demo-button">Request Demo</Link>
            </div>
            <Footer />
        </div>
    );
}

export default SES4;
