import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from '../components/slider';
import Footer from '../components/footer';
import Navigation from '../components/navigation';

import '../styles/homepage.css';

//media
//ses
import whatsappIcon from '../assets/whatsapp.webp';
import dataIcon from '../assets/data3.jpeg';
import chatIcon from '../assets/chat3.webp';
import apiIcon from '../assets/data1.jpg';
//marketing

import market1 from '../assets/market9.png';
import market2 from '../assets/media3.jpg';
import market3 from '../assets/market7.webp';
import market4 from '../assets/market4.jpg';

function HomePage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: ''
    });

    const softwareServices = [
        {
            name: "WhatsApp AI Integration", 
            desc: "Integrate AI capabilities with WhatsApp to automate customer interactions and improve engagement.", 
            icon: whatsappIcon
        },
        {
            name: "Custom API Development", 
            desc: "Develop custom APIs tailored to your business needs for seamless integration and functionality.", 
            icon: apiIcon
        },
        {
            name: "Chatbot Solutions", 
            desc: "Create intelligent chatbots to provide instant support and enhance user experience.", 
            icon: chatIcon
        },
        {
            name: "Business Process Automation", 
            desc: "Automate internal processes to increase efficiency and reduce manual workloads.", 
            icon: dataIcon
        }
    ]
    const marketingServices = [
        {
            name: "AI-Driven Analytics and Reporting", 
            desc: "Utilize AI to analyze data and generate insightful reports for better decision-making.",
            icon: market1
        },
        {
            name: "Media Marketing", 
            desc: "Implement effective media marketing strategies to reach a wider audience and increase engagement.",
            icon: market2
        },
        {
            name: "Social Media Management", 
            desc: "Manage your social media presence to build a strong brand and connect with customers.",
            icon: market3
        },
        {
            name: "Strategic Advertising", 
            desc: "Invest in paid advertising campaigns to drive traffic and increase conversions.",
            icon: market4
        }
    ]
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const isFormValid = formData.name && formData.email && formData.company;

    return (
        <div className="homepage-container">
            <Navigation />
            <Slider />
            <div className="home">
                <section className="grow-business">
                    <div className="grow-business-left">
                        <h2>Grow your business with NEXT</h2>
                        <p>Our company is dedicated to revolutionizing the way businesses operate by leveraging AI and advanced technology to create seamless, efficient solutions. We focus on transforming business processes, enhancing customer engagement, and driving growth through innovative, data-driven approaches. Our goal is to empower companies to stay ahead of the curve in an ever-evolving digital landscape.</p>
                        <p>Beyond our technical expertise, we integrate strategic marketing and media services to ensure that businesses not only run efficiently but also connect with their target audiences effectively. By combining intelligent automation with creative, targeted outreach, we help businesses achieve their goals and stand out in a crowded market. We're committed to being a trusted partner in your journey towards success.</p>
                    </div>
                </section>
                <section className="what-we-offer">
                    <div className="content">
                        <h2>What We Offer</h2>
                        {/* <p>We offer a wide range of services to meet your needs...</p> */}
                    </div>
                    <div className="services-wrapper">
                        <div className="services-row">
                            <h3>Software Engineering Services (SeS)</h3>
                            <div className="services-column">
                                {
                                    softwareServices.map(service => (
                                        <div key={service.name} className="service">
                                            <img src={service.icon} alt={`Image for service ${service.name}`} className="service-icon" />
                                            <h4>{service.name}</h4>
                                            <p>{service.desc}</p>
                                        </div>
                                    ))
                                }
                                {/* <Link to="/ses1" className="service">
                                    <img src={whatsappIcon} alt="WhatsApp AI Integration"
                                         className="service-icon" />
                                    <h4>WhatsApp AI Integration</h4>
                                    <p>Integrate AI capabilities with WhatsApp to automate customer interactions and improve engagement.</p>
                                </Link>
                                <Link to="/ses2" className="service">
                                    <img src={apiIcon} alt="Custom API Development"
                                         className="service-icon" />
                                    <h4>Custom API Development</h4>
                                    <p>Develop custom APIs tailored to your business needs for seamless integration and functionality.</p>
                                </Link>
                                <Link to="/ses3" className="service">
                                    <img src= {chatIcon} alt="Chatbot Solutions"
                                         className="service-icon" />
                                    <h4>Chatbot Solutions</h4>
                                    <p>Create intelligent chatbots to provide instant support and enhance user experience.</p>
                                </Link>
                                <Link to="/ses4" className="service">
                                    <img src={dataIcon} alt="Business Process Automation"
                                         className="service-icon" />
                                    <h4>Business Process Automation</h4>
                                    <p>Automate internal processes to increase efficiency and reduce manual workloads.</p>
                                </Link> */}
                            </div>
                        </div>
                        <div className="services-row">
                            <h3>Marketing and Media Services</h3>
                            <div className="services-column">
                                {
                                    marketingServices.map(service => (
                                        <div key={service.name} className="service">
                                            <img src={service.icon} alt={`Image for service ${service.name}`} className="service-icon" />
                                            <h4>{service.name}</h4>
                                            <p>{service.desc}</p>
                                         </div>
                                    ))
                                }
                                {/* <Link to="/mms1" className="service" onClick={e=>e.preventDefault()}>
                                    <img src={market1} alt="AI-Driven Analytics and Reporting"
                                         className="service-icon" />
                                    <h4>AI-Driven Analytics and Reporting</h4>
                                    <p>Utilize AI to analyze data and generate insightful reports for better decision-making.</p>
                                </Link>
                                <Link to="/mms2" className="service">
                                    <img src={market2} alt="Media Marketing"
                                         className="service-icon" />
                                    <h4>Media Marketing</h4>
                                    <p>Implement effective media marketing strategies to reach a wider audience and increase engagement.</p>
                                </Link>
                                <Link to="/mms3" className="service">
                                    <img src={market3} alt="Social Media Management"
                                         className="service-icon" />
                                    <h4>Social Media Management</h4>
                                    <p>Manage your social media presence to build a strong brand and connect with customers.</p>
                                </Link>
                                <Link to="/mms4" className="service">
                                    <img src={market4} alt="Strategic Advertising"
                                         className="service-icon" />
                                    <h4>Strategic Advertising</h4>
                                    <p>Invest in paid advertising campaigns to drive traffic and increase conversions.</p>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;
