import React from 'react';
import { Link } from 'react-router-dom';
import './navigation.css';

function Navigation() {
    return (
        <header className="navbar">
            <div className="navbar-logo">
                <Link to="/">
                    <img src="/next.png" alt="NEXT Logo" />
                </Link>
            </div>
            {/* <nav>
                <ul className="navbar-links">
                    <li className="dropdown">
                        <Link to="#" className="navbar-item">Services</Link>
                        <div className="dropdown-content">
                            <Link to="/services/service1" className="navbar-item">Service 1</Link>
                            <Link to="/services/service2" className="navbar-item">Service 2</Link>
                            <Link to="/services/service3" className="navbar-item">Service 3</Link>
                        </div>
                    </li>
                    <li className="dropdown">
                        <Link to="#" className="navbar-item">Industries</Link>
                        <div className="dropdown-content">
                            <Link to="/industries/industry1" className="navbar-item">Industry 1</Link>
                            <Link to="/industries/industry2" className="navbar-item">Industry 2</Link>
                            <Link to="/industries/industry3" className="navbar-item">Industry 3</Link>
                        </div>
                    </li>
                    <li><Link to="/insights" className="navbar-item">Insights</Link></li>
                    <li><Link to="/careers" className="navbar-item">Careers</Link></li>
                    <li><input type="text" placeholder="Search" className="search-bar" /></li>
                </ul>
            </nav> */}
            <div className="contact-consultant">
                <Link to="/contact" className="contact-link"><i className="phone-icon">📞</i> Contact a Consultant</Link>
                
            </div>
        </header>
    );
}

export default Navigation;
